import {ChildJournal} from '@nit-core/models/child-journal';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse, Journal, StudentOfJournal} from '@nit-models';
import {HTTPOptions, RestService} from '@nit-core/services/global/http-services/rest.service';
import {Filter, JournalClass} from '@nit-core/models/filter';
import {JournalEntity, ReplaceTeachersData} from '@nit-core/models/journal';
import {Teacher} from '@nit-core/models/teacher';

@Injectable({providedIn: 'root'})
export class JournalService extends RestService<Journal> {
  constructor() {
    super(Journal, '/journals', 'journal');
  }

  getJournalEntity(id: string): Observable<JournalEntity> {
    return this.httpGet(`${this.apiHref()}/${id}`, {factory: JournalEntity});
  }

  getJournalPage(id: string, query?: Filter): Observable<Journal> {
    return this.httpGet<Journal>(`${this.apiHref()}/${id}/pages`, {
      alias: 'journal', query: query
    });
  }

  getClasses(scheduleId: string, myClassesOnly: boolean = false): Observable<ApiResponse<JournalClass>> {
    return this.httpGetAll(`${this.apiHref()}/classes?scheduleId=${scheduleId}&myClassesOnly=${myClassesOnly}`,
      {alias: 'journal-classes', factory: JournalClass});
  }

  getNushClasses(scheduleId?: string, myClassesWithReplaceOnly?: boolean): Observable<ApiResponse<JournalClass>> {
    return this.httpGetAll(`${this.apiHref()}/classes?nushOnly=true${myClassesWithReplaceOnly ? '&myClassesWithReplaceOnly=' + myClassesWithReplaceOnly : ''}${scheduleId ? '&scheduleId=' + scheduleId : ''}`, {alias: 'journal-classes'});
  }

  getChildJournal(userId: string, date: { from?: string, to?: string }, schoolId?: number, options?: HTTPOptions): Observable<ChildJournal> {
    const params: { [param: string]: string | readonly string[] } = {
      userId,
      from: date.from,
      to: date.to,
    };

    const httpOptions: HTTPOptions = {
      alias: 'student-journal-marks',
      factory: ChildJournal,
      asUserId: options?.asUserId,
      asUserSchoolId: schoolId,
      query: params
    };

    return this.httpGet(`${this.apiHref()}/child-marks`, httpOptions);
  }

  getJournalTeachers(includeInactive: boolean, query = {}): Observable<ApiResponse<Teacher>> {
    const endpoint = `${this.apiHref()}/teachers?includeInactive=${includeInactive}`;

    return this.httpGetAll<Teacher>(endpoint, {factory: Teacher, query});
  }

  getStudentsOfJournal(journalId: string): Observable<ApiResponse<StudentOfJournal>> {
    return this.httpGetAll<StudentOfJournal>(`${this.apiHref()}/${journalId}/users`, {
      alias: 'journal-users',
      factory: StudentOfJournal
    });
  }

  replaceTeacherOrVacancy(id: string, payload: ReplaceTeachersData): Observable<void> {
    return this.httpPut(`${this.apiHref()}/${id}/change-teachers`, payload);
  }

  checkSubjectForJournal(subjectName: string): Observable<boolean> {
    return this.httpGet(`${this.apiHref()}/any-with-subject/${subjectName}`);
  }
}
