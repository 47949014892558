export class AikomSettings {
  lastSynchronized: Date;
  status: AikomConnectionStatus;
  userName: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.lastSynchronized = item.lastSynchronized ? new Date(item.lastSynchronized) : null;
  }
}

export class AikomConnection {
  userName: string;
  password: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export enum AikomConnectionStatus { Active = 0, Closed = 1 }
