import {NushDomain, NushSubject} from '.';
import {Subject} from '@nit-core/models/subject';


export class NushRule {
  id?: string;
  classIds: string[];
  classNumber?: number;
  certificate: CertificateSettings;
  certificateName?: string;
  firstSemesterSettings: SemesterSettings;
  secondSemesterSettings: SemesterSettings;
  yearAchievementGrading: Grading;
  overallEvaluationGrading: Grading;
  concurrencyStamp?: string;
  removeDefaultColumns?: boolean;
  syncLearningOutcomes?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.classIds = item.classIds ? [...item.classIds] : [];
    this.certificate = item.certificate ? new CertificateSettings(item.certificate) : null;
    this.firstSemesterSettings = item.firstSemesterSettings ? new SemesterSettings(item.firstSemesterSettings) : null;
    this.secondSemesterSettings = item.secondSemesterSettings ? new SemesterSettings(item.secondSemesterSettings) : null;
    this.yearAchievementGrading = item.yearAchievementGrading ? new Grading(item.yearAchievementGrading) : null;
    this.overallEvaluationGrading = item.overallEvaluationGrading ? new Grading(item.overallEvaluationGrading) : null;
  }
}

export class CertificateSettings {
  certificateId: string;
  subjects?: NushSubject[];
  domains?: NushDomain[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.subjects = item.subjects.length ? item.subjects.map(subject => new NushSubject(subject)) : [];
    this.domains = item.domains.length ? item.domains.map(domain => new NushDomain(domain)) : [];
  }
}

export class SemesterSettings {
  scheduleId: string;
  achievementGrading: Grading;
  diagnosticWorkGrading: Grading;
  studentsObservationGrading: Grading;
  overallEvaluationGrading: Grading;
  journalGrading: Grading;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.achievementGrading = item.achievementGrading ? new Grading(item.achievementGrading) : null;
    this.diagnosticWorkGrading = item.diagnosticWorkGrading ? new Grading(item.diagnosticWorkGrading) : null;
    this.studentsObservationGrading = item.studentsObservationGrading ? new Grading(item.studentsObservationGrading) : null;
    this.overallEvaluationGrading = item.overallEvaluationGrading ? new Grading(item.overallEvaluationGrading) : null;
    this.journalGrading = item.journalGrading ? new Grading(item.journalGrading) : null;
  }
}

export class Grading {
  isGradingForbidden: boolean;
  isLeveledGradingActive: boolean;
  isVerbalGradingActive: boolean;
  isDigitalGradingActive: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class DomainItem {
  id: string;
  name: string;
  subjects: Subject[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.subjects = item.subjects.length ? item.subjects.map(subject => new Subject(subject)) : [];
  }
}

export class RuleWithTitle extends NushRule {
  title: string;

  constructor(item: any = {}) {
    super(item);
    this.title = item.title;
  }
}

export class EditDomainEvent {
  domainIndex: number;
  domainName: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class DeleteSubjectEvent {
  domainIndex: number;
  subjectIndex: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class AddedSubjectsEvent {
  chosenSubjects: Subject[];
  domainName: string;
  domainIndex: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.chosenSubjects = item.chosenSubjects.length ? [...item.chosenSubjects] : [];
    this.chosenSubjects = item.chosenSubjects.length ? item.chosenSubjects.map(chosenSubject => new Subject(chosenSubject)) : [];
  }
}

export class EditOutcomeEvent {
  domainIndex: number;
  isOutcomesEditing: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
