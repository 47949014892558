import {DataBindingDirective, GridComponent} from '@progress/kendo-angular-grid';
import {Directive, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {FilterDescriptor, State} from '@progress/kendo-data-query';
import {ActivatedRoute} from '@angular/router';
import {ApiResponse, EducationPlan} from '@nit-models';
import {AuthService} from '@nit-auth';
import {CompositeFilterDescriptor} from '@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface';

@Directive({
  selector: '[gridBulk]',
  exportAs: 'gridBulkDirective'
})
export class GridBulkDirective extends DataBindingDirective implements OnInit {
  @Input() restService: RestService<any>;
  @Input() queryParams: any = {};
  @Input() defaultFilter = new Array<FilterDescriptor>();
  @Input() preload = true;
  @Input() userId: string;
  @Input() schoolId?: number;
  @Output() educationPlanNextDate: EventEmitter<ApiResponse<EducationPlan>> = new EventEmitter<ApiResponse<EducationPlan>>();
  @Output() totalCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() gridData: EventEmitter<any> = new EventEmitter<any>();
  private readonly _userId: string;

  constructor(private readonly _grid: GridComponent, private readonly _route: ActivatedRoute, private readonly _authService: AuthService) {
    super(_grid);
    this.pageSize = this.pageSize ?? 20;
    this._grid.sortable = true;
    this._grid.filterable = true;
    this._grid.pageable = true;
    this._grid.resizable = true;
    this._userId = this.userId ?? this._route.snapshot.params?.userId;
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.preload) {
      this.rebind();
    }
  }

  rebind(): void {
    this.state.skip = this._grid.skip;
    this.grid.loading = true;
    const params = this._getParams(this.state);

    this.restService?.all({query: params, asUserId: this._userId, asUserSchoolId: this.schoolId})
      .subscribe((res: any) => {
        this.grid.loading = false;
        this._grid.data = res;
        this.educationPlanNextDate.emit(res);
        this.totalCount.emit(res.total);
        this.gridData.emit(res);
        this.notifyDataChange();
      });
  }

  clearFilters(): void {
    this._grid.filter = null;
    this.state.filter = null;
  }

  private _getParams(state: State): any {
    const query: any = {};

    if (state.take) {
      query.take = state.take;
    }
    if (this._grid.skip) {
      query.skip = this._grid.skip;
    }

    const filters = ([...state.filter?.filters ?? [], ...this.defaultFilter]) as FilterDescriptor[];

    if (filters) {
      const filterLogicDescriptor = {logic: 'and'} as CompositeFilterDescriptor;
      query['filter[logic]'] = filterLogicDescriptor.logic;
      const filterTemplate = 'filter[filters]';
      filters.forEach((res, index) => {
        query[`${filterTemplate}[${index}][field]`] = res.field;
        query[`${filterTemplate}[${index}][operator]`] = res.operator;
        query[`${filterTemplate}[${index}][value]`] = res.value instanceof Date ? new Date(res.value.setHours(23, 59, 59)).toJSON() : res.value;
      });
    }

    if (state.sort) {
      const sortTemplate = 'sort';
      state.sort.forEach((value, index) => {
        if (value.dir) {
          query[`${sortTemplate}[${index}][field]`] = value.field;
          query[`${sortTemplate}[${index}][dir]`] = value.dir;
        }
      });
    }

    return query;
  }
}
