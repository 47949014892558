import {Class} from './class';
import {SemesterNumber} from '@nit-core/global/domain/enums';

export class NewAcademicYear {
  id?: string;
  name: string;
  displayName: string;
  classIds: string[];
  classes?: Class[];
  firstSemester: AcademicSemesterBounds;
  secondSemester: AcademicSemesterBounds;
  lastPublish?: Date;
  lastPublishFrom?: Date;
  lastPublishTo?: Date;
  publishState?: number;
  lessons: Lesson[];
  weeksIterationNumber: number;
  applyDayOffsForWeeks: boolean;
  startFromDenominator: boolean;
  studyDays: number[];
  yearEnd?: number;
  yearStart?: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.firstSemester = item.firstSemester ? new AcademicSemesterBounds(item.firstSemester) : null;
    this.secondSemester = item.secondSemester ? new AcademicSemesterBounds(item.secondSemester) : null;
    this.classIds = item.classIds ? [...item.classIds] : [];
    this.studyDays = item.studyDays ? [...item.studyDays] : [];
    this.lessons = item.lessons ? item.lessons.map(lesson => new Lesson(lesson)) : [];
    this.lastPublish = item.lastPublish ? new Date(item.lastPublish) : null;
    this.lastPublishFrom = item.lastPublishFrom ? new Date(item.lastPublishFrom) : null;
    this.lastPublishTo = item.lastPublishTo ? new Date(item.lastPublishTo) : null;
  }
}

export class Lesson {
  index: number;
  number: number;
  name: string;
  type: number;
  from: Date;
  to: Date;
  classroom: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.from = item.from ? new Date(item.from) : null;
    this.to = item.to ? new Date(item.to) : null;
  }
}

export class Semester {
  id: string;
  semester: SemesterNumber;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class AcademicSemesterBounds {
  id?: string;
  from?: string;
  to?: string;
  semester?: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ActiveAcademicYear {
  firstSemester: Semester;
  secondSemester: Semester;
  yearStart: number;
  yearEnd: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.firstSemester = item.firstSemester ? new Semester(item.firstSemester) : null;
    this.secondSemester = item.secondSemester ? new Semester(item.secondSemester) : null;
  }
}

export type LessonsWithStudyDays = Pick<NewAcademicYear, 'lessons' | 'studyDays'>;
