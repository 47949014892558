import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Course} from '@nit-core/models';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CourseService extends RestService<Course> {
  constructor() {
    super(Course, '/courses', 'courses');
  }

  updatePermissions(id: string, data: any): Observable<any> {
    return this.httpPost(`${this.apiHref()}/${id}/permissions`, data);
  }

}
