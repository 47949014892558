import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';
import {ApiResponse} from '@nit-models';
import {AikomConnection, AikomSettings} from '@nit-core/models/aikom-settings';

@Injectable({
  providedIn: 'root'
})
export class AikomService extends RestService<AikomConnection> {

  constructor() {
    super(AikomConnection, '/settings/aikom', 'settings/aikom');
  }

  getAikomConnection(): Observable<AikomSettings> {
    return this.httpGet(this.apiHref());
  }

  deleteAikomConnection(): Observable<ApiResponse<void>> {
    return this.httpDelete(this.apiHref());
  }
}
