import {AnswerType, ContentType} from '@nit-core/global/domain/enums';

export class Course {
  title?: string;
  description?: string;
  visible: boolean = true;
  class: string;
  sponsors: string;
  img: string;
  descClass: string;
  id?: string;
  logo?: string;
  permissions?: any;
  status?: number;
  type?: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class CourseWithContent extends Course {
  lections?: CourseLesson[];

  constructor(item: any = {}) {
    super(item);
    Object.assign(this, item);
    this.lections = !item?.lections?.length ? [] :
      item.lections.map(lecture => new CourseLesson(lecture));
  }
}

export class CourseLesson {
  id: string;
  createdAt: string;
  order: number;
  name: string;
  courseId: string;
  content: (LessonContent | TestQuestionList)[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.content = item.content?.length ? item.content.map(contentItem => {
      return contentItem.questions ?
        new TestQuestionList(contentItem) : new LessonContent(contentItem);
    }) : [];
  }
}

export class LessonContent {
  id: string | number;
  type: ContentType;
  text?: string;
  title?: string;
  items?: LessonContentItem[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.items = !item?.items?.length ? [] :
      item.items.map(contentItem => new LessonContentItem(contentItem));
  }
}

export class LessonContentItem {
  id: string | number;
  link: string;
  title: string;
  type: ContentType;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class TestQuestionList {
  id: string;
  type: ContentType;
  questions: TestQuestion[];
  minimumCorrectAnswersPercentage: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.questions = !item.questions?.length ? [] :
      item.questions.map(questions => new TestQuestion(questions));
  }
}

export class TestQuestion {
  id: string;
  question: string;
  answerType: AnswerType;
  answers: TestAnswer[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.answers = !item.answers?.length ? [] :
      item.answers.map(answer => new TestAnswer(answer));
  }
}

export class TestAnswer {
  id: string;
  answer: string;
  isCorrect: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class CompleteTestBody {
  blockId: string;
  answers: CompleteTestAnswer[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.answers = !item.answers?.length ? [] :
      item.answers.map(answer => new CompleteTestAnswer(answer));
  }
}

export class CompleteTestAnswer {
  questionId: string;
  answerIds: string[];

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class CompleteTestResponse {
  correctAnswersGivenPercentage: number;
  minimumCorrectAnswersPercentage: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class SafeguardQuestions {
  [key: string]: SafeguardInfo;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.key = item.key ? new SafeguardInfo(item.key) : null;
  }
}

export class SafeguardInfo {
  title: string;
  answers: Answers[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.answers = item?.answers ? item?.answers.map(x => new Answers(x)) : [];
  }
}

export class Answers {
  value: number;
  text: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class VideoCourse {
  title: string;
  link: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class SafeguardQuestionsResponse {
  id: string;
  userId: string;
  isSuccess: boolean;
  testResult: TestResult;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.testResult = item.testResult ? new TestResult(item.testResult) : null;
  }
}

export class TestResult {
  [key: string]: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class SafeguardReport {
  courseId: string;
  description: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class getPass {
  isViewed: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ColumbiaUniversityResponse {
  id: string;
  userId: string;
  isSuccess: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
