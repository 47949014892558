import {ApiResponse} from '@nit-models';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {finalize, map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {HTTPService} from '@nit-core/services/global/http-services/http.service';
import {ServiceType} from '@nit-core/global/domain/enums';
import {AuthService} from '@nit-auth';
import {AppInjector} from '@nit-core/global/nit.injector';
import {environment} from '../../../../../environments/environment';

export abstract class RestService<T> extends HTTPService {
  protected requestFinished$: Subject<void> = new Subject<void>();

  constructor(private readonly _type: (new (value: any) => T), path: string, private readonly _alias: string, origin?: string | ServiceType) {
    super(path, origin);
  }

  read(id: string, options?: HTTPOptions): Observable<T> {
    return this.httpGet<T>(`${this.apiHref()}/${id}`, options);
  }

  all(options?: HTTPOptions): Observable<ApiResponse<T>> {
    return this.httpGetAll<T>(this.apiHref(), options);
  }

  create(data: T | FormData, schoolId: number = null, userId: string = null): Observable<any> {
    return this.httpPost(this.apiHref(), data, schoolId, userId);
  }

  update<A>(id: string, data: A | T | FormData, options?: HTTPOptions): Observable<any> {
    return this.httpPut(`${this.apiHref()}/${id}`, data, options);
  }

  delete(id: string, options?: HTTPOptions): Observable<any> {
    return this.httpDelete(`${this.apiHref()}/${id}`, options);
  }

  protected httpGet<A = T>(absoluteUrl: string, options?: HTTPOptions): Observable<A> {
    const headers = this.getHeaders(options?.asUserId, options?.asUserSchoolId);

    return this.http.get<A>(absoluteUrl, {
      headers: this.aliasHeaders(options?.alias ?? this._alias, headers),
      params: new HttpParams({fromObject: options?.query})
    }).pipe(
      map((response: any) => this.instanceOf<A>(response, options?.factory || this._type)),
      finalize(() => this.requestFinished$.next())
    );
  }

  protected httpGetBlob(absoluteUrl: string, options: HTTPOptions): Observable<Blob> {
    const headers = this.getHeaders(options?.asUserId, options?.asUserSchoolId);

    return this.http.get(absoluteUrl, {
      responseType: 'blob',
      headers: this.aliasHeaders(options?.alias ?? this._alias, headers),
      params: new HttpParams({fromObject: options?.query})
    }).pipe(
      map((res: any) => {
        return new Blob([res], {type: res.type});
      })
    );
  }

  protected httpGetAll<A = T>(absoluteUrl: string, options?: HTTPOptions): Observable<ApiResponse<A>> {
    const headers = this.getHeaders(options?.asUserId, options?.asUserSchoolId);

    return this.http.get<ApiResponse<A>>(absoluteUrl, {
      headers: this.aliasHeaders(options?.alias ?? this._alias + '-all', headers),
      params: new HttpParams({fromObject: options?.query})
    }).pipe(
      map((response: any) => new ApiResponse<A>(response, options?.factory || this._type)),
      finalize(() => this.requestFinished$.next())
    );
  }

  protected httpPut<A>(absoluteUrl: string, data: A | T | FormData, options?: HTTPOptions): Observable<any> {
    const headers = this.getHeaders(options?.asUserId, options?.asUserSchoolId, this.defaultHeaders(data));

    return this.http.put<any>(absoluteUrl, this.body(data), {headers: headers}).pipe(
      finalize(() => this.requestFinished$.next())
    );
  }

  protected httpDelete(absoluteUrl: string, options?: HTTPOptions, data?: any): Observable<any> {
    const headers = this.getHeaders(options?.asUserId, options?.asUserSchoolId);
    if (!options) {
      return this.http.delete<any>(absoluteUrl, {
        headers: headers,
        body: data ? data : {}
      })
        .pipe(
          finalize(() => this.requestFinished$.next())
        );
    }

    return this.http.delete<any>(absoluteUrl, {
      headers: headers,
      params: new HttpParams({fromObject: options?.query}),
      body: data ? data : {}
    })
      .pipe(
        finalize(() => this.requestFinished$.next())
      );
  }

  protected httpPost<A = T, R = any>(absoluteUrl: string, data: A | T | FormData, schoolId: number = null, userId: string = null): Observable<R> {
    const headers = this.getHeaders(userId, schoolId, this.defaultHeaders(data));

    return this.http.post<any>(absoluteUrl, this.body(data), {headers: headers}).pipe(
      finalize(() => this.requestFinished$.next())
    );
  }

  protected httpPostBlob<A = T>(absoluteUrl: string, data: A | T | FormData, options?: HTTPOptions): Observable<Blob> {
    const headers = this.getHeaders(options?.asUserId, options?.asUserSchoolId);

    return this.http.post(absoluteUrl, this.body(data),{
      responseType: 'blob',
      headers: this.aliasHeaders(options?.alias ?? this._alias, headers),
      params: new HttpParams({fromObject: options?.query})
    }).pipe(
      map((res: any) => {
        return new Blob([res], {type: res.type});
      })
    );
  }

  protected instanceOf = <A>(item: any, factory: (new (value: any) => any)): A | null => item ? new factory(item) : null;

  protected normalizeParams(object: any): { [param: string]: string | readonly string[] } {
    const normalized: { [key: string]: string } = {};
    Object.keys(object).forEach(property => {
      normalized[property] = object[property];
    });

    return normalized;
  }

  protected getHeaders(asUserId?: string, asSchoolId?: number, headers?: HttpHeaders): HttpHeaders {
    let newHeaders = headers ?? this.getApiHeaders();
    const authService = this._getAuthService();
    if (!!authService.userId || !!asUserId) {
      newHeaders = asUserId
        ? newHeaders.set('X-NIT-REQUESTED-USER-ID', asUserId)
        : newHeaders.set('X-NIT-REQUESTED-USER-ID', authService.userId);
    }

    if (asSchoolId || asSchoolId === 0) {
      newHeaders = newHeaders.set('X-NIT-REQUESTED-SCHOOL-ID', asSchoolId.toString());
    } else {
      const schoolId = authService.getUserSchoolId();
      newHeaders = newHeaders.set('X-NIT-REQUESTED-SCHOOL-ID', schoolId.toString());
    }
    newHeaders = newHeaders.set('X-NIT-CLIENT', 'web');
    newHeaders = newHeaders.set('X-NIT-CLIENT-VERSION', environment.version);

    return newHeaders;
  }

  private readonly _getAuthService = (): AuthService => AppInjector.getInjector().get(AuthService);
}

export interface HTTPOptions {
  alias?: string;
  query?: { [param: string]: string | readonly string[] };
  factory?: (new (value: any) => any);
  asUserId?: string;
  asUserSchoolId?: number;
}
