// TODO rethink about models that are sending, so there could be an abstract model
import {FileTypeEnum, ReportState} from '@nit-core/global/domain/enums';

export class Report {
  description: string;
  errorMessage: string;
  id: string;
  reportType: FileTypeEnum;
  state: ReportState;
  attachment: ReportAttachment;
  createdAt: Date;
  title?: string;
  isSigned: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.attachment = item.attachment ? new ReportAttachment(item.attachment) : undefined;
    this.createdAt = item.createdAt ? new Date(item.createdAt) : null;
  }
}

export class ReportAttachment {
  id: string;
  fileName: string;
  filePath: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class FilterJournalVisitingReport {
  classId: string;
  scheduleId: string;
  from: string;
  to: string;
  description: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class FilterMarksChangelogReport {
  description: string;
  classId: string;
  scheduleId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class FilterClassReport {
  description: string;
  classId: string;
  scheduleId: string;
  subjects: string[];
  yearlyReport: boolean;
  includePages: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class FilterUsersReport {
  description: string;
  userType: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class GradeBookLoggingReport {
  schoolIds: number[];
  from: string;
  to: string;
  useAllSchools: boolean;
  fileName?: string;
  description?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class GeneralReport {
  schoolIds?: number[];
  minStudentsInClass: number;
  minSubjectsInClass: number;
  minJournalsInClass: number;
  fileName?: string;
  description?: string;
  useAllSchools: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class FilterSocialGroupReport {
  classId: string;
  description: string;
  isGeneral: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
