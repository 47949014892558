import {Injectable} from '@angular/core';
import {NewAcademicYear} from '@nit-core/models/new-academic-year';
import {Observable, of, switchMap} from 'rxjs';
import {AcademicYearService} from '@nit-core/services';

@Injectable({
  providedIn: 'root'
})
export class ScheduleNameService {
  schedules: NewAcademicYear[];

  constructor(private readonly _academicYearsService: AcademicYearService) { }

  getScheduleName(scheduleId: string): Observable<string> {
    if (scheduleId === 'general') {
      return of('Загальний розклад');
    }

    return this._academicYearsService.all().pipe(switchMap(({data}) => {
      this.schedules = data;
      const scheduleName = this.schedules.find(schedule => schedule.id === scheduleId)?.name;

      return of(scheduleName);
    }));
  }
}
