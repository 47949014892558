import {Injectable} from '@angular/core';
import {Olympiads} from '@nit-core/models/marks-changelog';
import {Observable, map} from 'rxjs';
import {ChildAccessibleOlympiad, OlympiadPaymentFormRequest, OlympiadPaymentResponse, OlympiadValidatePaymentRequest} from '@nit-core/models/olympiads';
import {RestService} from '../global/http-services/rest.service';

@Injectable({
  providedIn: 'root'
})

export class OlympiadService extends RestService<ChildAccessibleOlympiad> {

  constructor() { super(ChildAccessibleOlympiad, '/olympiads', 'olympiads'); }

  getStatus(classNumber: number): Observable<Olympiads> {
    return this.http.get<Olympiads>(`${this.apiOrigin}/api/olympiads/check-unpaid-available-olympiads/${classNumber}`);
  }

  getAccessibleOlympiads(userId: string, schoolId: number): Observable<ChildAccessibleOlympiad[]> {
    return this.http.get<ChildAccessibleOlympiad[]>(`${this.apiOrigin}/api/olympiads/check-accessible-olympiads-all`, {
      headers: this.getHeaders(userId, schoolId)
    }).pipe(map(response => response.map(x => new ChildAccessibleOlympiad(x))));
  }

  getOlympiadPaymentInfo(childId: string, childSchoolId: number, request: OlympiadPaymentFormRequest): Observable<OlympiadPaymentResponse> {
    return this.http.post<OlympiadPaymentResponse>(`${this.apiOrigin}/api/payment/liqpay`, request, {
      headers: this.getHeaders(childId, childSchoolId)
    });
  }

  validateOlympiadPayment(childId: string, childSchoolId: number, request: OlympiadValidatePaymentRequest) {
    return this.httpPost(`${this.apiOrigin}/api/payment/validate`, request, childSchoolId, childId);
  }
}
