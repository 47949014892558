import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DateTransformService {

  setDateFormat(date: Date = new Date()): number {
    return new Date(new Date(date)).setHours(0, 0, 0, 0);
  }

  getTimeZoneOffset(date): number {
    return new Date(date).setHours(date.getHours() + (new Date().getTimezoneOffset() / 60));
  }

  transformDate(date: Date): any {
    const value = new Date(date);
    const timeZone = value?.getTimezoneOffset() / 60;
    value.setHours(value.getHours() - timeZone);

    return value.toISOString();
  }

  transformDateNoString(date: Date): Date {
    const value = new Date(date);
    const timeZone = value?.getTimezoneOffset() / 60;
    value.setHours(value.getHours() + timeZone);

    return value;
  }

}
