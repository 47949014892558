export class NushCertificate {
  id: string;
  classNumbers: number[];
  name: string;
  subjects: NushSubject[];
  domains: NushDomain[];
  usesDomains: boolean;
  usesSubjects: boolean;
  hasSchedules: boolean;
  hasTotalLearningOutcome: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.subjects = item.subjects?.length ? item.subjects.map(subject => new NushSubject(subject)) : [];
    this.domains = item.domains?.length ? item.domains.map(domain => new NushDomain(domain)) : [];
    this.classNumbers = item.classNumbers?.length ? [...item.classNumbers] : [];
  }
}

export class NushDomain {
  id?: string;
  name: string;
  subjects: NushSubject[];
  learningOutcomes?: LearningOutcome[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.subjects = item.subjects?.length ? item.subjects.map(subject => new NushSubject(subject)) : [];
    this.learningOutcomes = item.learningOutcomes?.length ?
      item.learningOutcomes.map(outcome => new LearningOutcome(outcome)) : [];
  }
}

export class NushSubject {
  id?: string;
  name: string;
  learningOutcomes: LearningOutcome[];
  isFds: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.learningOutcomes = item.learningOutcomes?.length ?
      item.learningOutcomes.map(outcome => new LearningOutcome(outcome)) : [];
  }
}

export class LearningOutcome {
  id?: string;
  name: string;
  isImmutable: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class AllowedSubject {
  id?: string;
  name: string;
  isActive?: boolean;
  learningOutcomes: LearningOutcome[];
  isFds: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.learningOutcomes = item.learningOutcomes?.length ?
      item.learningOutcomes.map(outcome => new LearningOutcome(outcome)) : [];
  }
}
