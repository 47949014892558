import {Injectable} from '@angular/core';
import {combineLatest, map, Observable, of, switchMap} from 'rxjs';
import {JournalService, VacancyService} from '@nit-services';

@Injectable({
  providedIn: 'root'
})
export class ReplacementNameService {
  constructor(private readonly _vacanciesService: VacancyService,
              private readonly _journalService: JournalService) { }

  getReplacementName(scheduleId: string): Observable<string> {
    return combineLatest([
      this._journalService.getJournalTeachers(true),
      this._vacanciesService.all({query: {take: '999'}})
    ])
      .pipe(
        map(([teachers, vacancies]) => [...teachers.data, ...vacancies.data]),
        switchMap((response) => {
          const scheduleName = response.find(schedule => schedule.id === scheduleId)?.shortName;

          return of(scheduleName);
        })
      );
  }
}
