<div #dropdownContainer class="dropdown-container"
     [ngStyle]="{'height': height+'px'}"
     [class.border]="border">
  <div class="select"
       [class.disabled]="disabled"
       (click)="toggleDropdown()">
    <div>
      <span *ngIf="(selectedItem && selectedItem?.[textField] !== defaultItem?.[textField]) || !defaultItem?.[textField]"
            class="name"
            [ngStyle]="{'max-width': (selectedItem?.['isFds'] ? '90%' : '100%')}">
            {{textField ? selectedItem?.[textField] : selectedItem}}
      </span>
      <span *ngIf="defaultItem?.[textField] && (!selectedItem?.[textField] || selectedItem?.[textField] === defaultItem?.[textField])"
            class="name default-item">
            {{defaultItem?.[textField]}}
      </span>
      <span *ngIf="selectedItem?.['isFds']" class="fds-label">ШПД</span>
      <ng-container *ngIf="headerTemplateRef && showContentTextSelectItem"
                    [ngTemplateOutlet]="headerTemplateRef"
                    [ngTemplateOutletContext]="{$implicit:selectedItem}">
      </ng-container>
    </div>
    <span class="triangle">&#x25BE;</span>
  </div>

  <div *ngIf="isOpened"
       class="options-container" 
       [ngClass]="[data.length ? '' : 'empty-container']">
    <div *ngIf="data.length; else empty" class="options-list">
      <div *ngIf="defaultItem" 
           class="option default-item"
           (click)="choseValue(defaultItem)">
        {{defaultItem?.[textField]}}
      </div>
      <div *ngFor="let item of data" class="option" 
           [ngStyle]="{'justify-content': justifyContent}"
           [class.selected]="item?.[textField] === selectedItem?.[textField] || item === selectedItem"
           (click)="choseValue(item)">
        <span [ngStyle]="{'max-width': item?.isFds ? '90%' : '100%'}">
          {{textField ? item?.[textField] : item}}
        </span>
        <span *ngIf="item?.isFds" class="fds-label">ШПД</span>
        <ng-container *ngIf="headerTemplateRef"
                      [ngTemplateOutlet]="headerTemplateRef"
                      [ngTemplateOutletContext]="{$implicit:item}">
        </ng-container>
      </div>
    </div>

    <ng-template #empty>
      {{emptyText}}
    </ng-template>

    <button *ngIf="isDraft" class="draft-btn" (click)="addDraft()">
      <span>
        +
      </span>
      Додати чернетку
    </button>
  </div>
</div>