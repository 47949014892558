import {CustomMark, LeveledAssessmentType} from '@nit-core/global/domain/enums';
import {groupByKeys} from '@nit-core/methods';

class Mark {
  id: string;
  description: string;
  rating: number;
  isFinalMark: boolean;
  isVerbalFormed: boolean;
  customDigitalRating: CustomMark;
  leveledAssessmentType: LeveledAssessmentType;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class GrouppedMarks<T> {
  marks: {
    [key: string]: {
      [key: string]: T[]
    }
  };
  constructor(marks: any = [], colKey: string, rowKey: string) {
    const groupped = groupByKeys(marks, [colKey, rowKey]);
    this.marks = groupped;
  }

  public get(colId: string, rowId: string): T | null {
    return this.marks[colId]?.[rowId] ? this.marks[colId][rowId][0] : null;
  }
}

export class StudentsObservationMark extends Mark {
  studentsObservationId: string;
  columnId: string;
  rowId: string;
  constructor(item: any = {}) {
    super(item);
    Object.assign(this, item);
  }
}

export class AchievementMark extends Mark {
  learningOutcomeId: string;
  achievementId: string;
  constructor(item: any = {}) {
    super(item);
    Object.assign(this, item);
  }
}

export class DiagnosticWorkMark extends Mark {
  diagnosticWorkId: string;
  columnId: string;
  userId: string;
  constructor(item: any = {}) {
    super(item);
    Object.assign(this, item);
  }
}
