/*
  keep enum value equal to the array index, we use quick access to the value by array index
  example: LegalFormSelect[LegalForm.Private].text
*/

import {AdministratorRole, SchoolType, LegalForm, GeneralPositionsEnum, FdsPositions} from './enums';

export const LegalFormSelect = [
  {value: LegalForm.Governmental, text: 'Державна'},
  {value: LegalForm.Private, text: 'Приватна'},
  {value: LegalForm.Communal, text: 'Комунальна'}
];

export const SchoolTypeSelect = [
  {value: SchoolType.School, text: 'Школа'},
  {value: SchoolType.Lyceum, text: 'Ліцей'},
  {value: SchoolType.Gymnasium, text: 'Гімназія'},
  {value: SchoolType.PrimarySchool, text: 'Початкова школа'},
  {value: SchoolType.SupportInstitution, text: 'Опорний заклад'},
];

export const SchoolRoleSelect = [
  {value: AdministratorRole.Director, text: 'Директор', priority: 1},
  {value: AdministratorRole.Secretary, text: 'Секретар', priority: 8},
  {value: AdministratorRole.TeacherOrganizer, text: 'Педагог-організатор', priority: 9},
  {value: AdministratorRole.Psychologist, text: 'Психологічний супровід', priority: 10},
  {value: AdministratorRole.Assistant1, text: 'Заступник директора з НВР з питань методичного забезпечення', priority: 2},
  {value: AdministratorRole.Assistant2, text: 'Заступник директора з НВР з іноземних мов', priority: 4},
  {value: AdministratorRole.Assistant3, text: 'Заступник директора з НВР з питань організації навчально-виховного процесу', priority: 3},
  {value: AdministratorRole.Assistant4, text: 'Заступник директора з ВР', priority: 5},
  {value: AdministratorRole.Assistant5, text: 'Заступник директора з АГП', priority: 7},
  {value: AdministratorRole.Assistant6, text: 'Заступник директора з НВР (Початкова школа)', priority: 6},
  {value: null, text: 'Оберіть роль...'},
];

export const GeneralPositionsList = [
  {value: GeneralPositionsEnum.Director, text: 'Директор'},
  {value: GeneralPositionsEnum.DeputyDirectorOfEducationalWork, text: 'Заступник директора з навчальної роботи'},
  {value: GeneralPositionsEnum.DeputyOfEducationalPedagogicWork, text: 'Заступник з навчально-виховної роботи'},
  {value: GeneralPositionsEnum.DeputyOfForeignLanguages, text: 'Заступник з іноземних мов'},
  {value: GeneralPositionsEnum.DeputyOfPrimaryClasses, text: 'Заступник з початкових класів'},
  {value: GeneralPositionsEnum.DeputyOfPedagogicWork, text: 'Заступник виховної роботи'},
  {value: GeneralPositionsEnum.DeputyOfEducationalWork, text: 'Заступник наукової роботи'},
  {value: GeneralPositionsEnum.Teacher, text: 'Вчитель'},
  {value: GeneralPositionsEnum.PrimarySchoolTeacher, text: 'Вчитель початкових класів'},
  {value: GeneralPositionsEnum.TeacherAssistant, text: 'Асистент вчителя'},
  {value: GeneralPositionsEnum.PedagogueOrganizator, text: 'Педагог-організатор'},
  {value: GeneralPositionsEnum.PracticalPsychologist, text: 'Практичний психолог'},
  {value: GeneralPositionsEnum.SocialPedagogue, text: 'Соціальний педагог'},
  {value: GeneralPositionsEnum.HeadOfGroup, text: 'Керівник гуртка'},
  {value: GeneralPositionsEnum.MasterOfIndustrialTraining, text: 'Майстер виробничого навчання'},
  {value: GeneralPositionsEnum.Educator, text: 'Вихователь'},
  {value: GeneralPositionsEnum.EducatorAssistant, text: 'Асистент вихователя'},
  {value: GeneralPositionsEnum.HeadOfSpeechTherapyCenter, text: 'Завідувач логопедичного пункту'},
  {value: GeneralPositionsEnum.SpeechTherapistTeacher, text: 'Вчитель-логопед'},
  {value: GeneralPositionsEnum.SpeechTherapistTeacherAssistant, text: 'Асистент вчителя-логопеда'},
  {value: GeneralPositionsEnum.DefectologistTeacher, text: 'Вчитель-дефектолог'},
  {value: GeneralPositionsEnum.AssistantOfDefectologistTeacher, text: 'Асистент вчителя-дефектолога'},
  {value: GeneralPositionsEnum.MusicDirector, text: 'Музичний керівник'},
  {value: GeneralPositionsEnum.PhysicalEducationInstructor, text: 'Інструктор з фізкультури'},
  {value: GeneralPositionsEnum.DeputyDirectorEconomicWork, text: 'Заступник директора з господарської роботи'},
  {value: GeneralPositionsEnum.HeadOfLibrary, text: 'Завідувач бібліотеки'},
  {value: GeneralPositionsEnum.Librarian, text: 'Бібліотекар'},
  {value: GeneralPositionsEnum.LaboratoryAssistant, text: 'Лаборант'},
  {value: GeneralPositionsEnum.Secretary, text: 'Секретар'}
];

export const FdsPositionsList = [
  {value: FdsPositions.Teacher, text: 'Вчитель'},
  {value: FdsPositions.TeacherOfPrimaryClasses, text: 'Вчитель початкових класів'},
  {value: FdsPositions.AssistantTeacher, text: 'Асистент вчителя'}
];
