import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'noTimezoneTime'
})
export class NoTimezoneTimePipe implements PipeTransform {
  transform(date: Date | string): string {
    const dateString = date instanceof Date ? date.toISOString() : date;
    const timeArray = dateString.split('T')[1].split(':');
    const hours = +timeArray[0] < 10 ? timeArray[0].slice(1) : timeArray[0];

    return hours + ':' + timeArray[1];
  }
}
