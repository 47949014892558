import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {School} from '@nit-models';
import {Observable} from 'rxjs';
import {SchoolStatus} from '@nit-core/models/school';

@Injectable({providedIn: 'root'})
export class SchoolService extends RestService<School> {
  constructor() {
    super(School, '/school', 'school');
  }

  getCurrentSchool(childId?: string, schoolId?: string): Observable<School> {
    return this.httpGet(this.apiHref(),
      {alias: 'current-school', factory: School, asUserId: childId, asUserSchoolId: Number(schoolId)});
  }

  updateCurrentSchool(data: School): Observable<any> {
    return this.httpPut(this.apiHref(), data);
  }

  updateImage(data: FormData): Observable<any> {
    return this.httpPut(`${this.apiHref()}/image`, data);
  }

  removeImage(): Observable<any> {
    return this.httpDelete(`${this.apiHref()}/image`);
  }

  getSchoolStatus(userId?: string, schoolId?: number): Observable<SchoolStatus> {
    return this.httpGet<SchoolStatus>(`${this.apiHref()}/status`, {asUserId: userId, asUserSchoolId: schoolId});
  }

  closeYear(): Observable<any> {
    return this.httpPost(`${this.apiHref()}/close-year`, {});
  }

  removeSchool(data: {schoolId: number}): Observable<void> {
    return this.httpDelete(this.apiHref(), {}, data);
  }
}
