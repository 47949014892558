import {Decision} from './decision';
import {SemesterSettings} from './nush-rule';

export class NushDecision extends Decision {
  classId: string;
  firstName: string;
  fullName: string;
  id: string;
  lastName: string;
  leaveTransferId?: string;
  middleName: string;
  userId: string;
  rule: NushDecisionRule;

  constructor(item: any = {}) {
    super(item);
    Object.assign(this, item);
    if (item.rule) this.rule = new NushDecisionRule(item.rule);
  }
}

export class NushDecisionRule {
  certificate: NushDecisionCertificate;
  firstSemesterSettings: SemesterSettings;
  secondSemesterSettings: SemesterSettings;

  constructor(item: any = {}) {
    Object.assign(this, item);
    if (item.certificate) this.certificate = new NushDecisionCertificate(item.certificate);
  }
}

export class NushDecisionCertificate {
  domains: NushDecisionDomain[];
  subjects: NushDecisionSubject[];
  name: string;
  id: string;
  generalCharacteristics: NushDecisionLearningOutcome[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    if (item.generalCharacteristics) this.generalCharacteristics = item.generalCharacteristics.map(x => new NushDecisionLearningOutcome(x));
    if (item.domains) this.domains = item.domains.map(x => new NushDecisionDomain(x));
    if (item.subjects) this.subjects = item.subjects.map(x => new NushDecisionSubject(x));
  }
}

export class NushDecisionDomain {
  id?: string;
  name: string;
  subjects: NushDecisionSubject[];
  constructor(item: any = {}) {
    Object.assign(this, item);
    if (item.subjects) this.subjects = item.subjects.map(x => new NushDecisionSubject(x));
  }
}

export class NushDecisionSubject {
  name: string;
  learningOutcomes: NushDecisionLearningOutcome[];
  domainId?: string;
  isFds?: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    if (item.learningOutcomes) this.learningOutcomes = item.learningOutcomes.map(x => new NushDecisionLearningOutcome(x));
  }
}

export class NushDecisionLearningOutcome {
  id: string;
  isImmutable?: boolean;
  name: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
