import {Mark} from '@nit-core/models/journal';
import {AttachedFile} from '@nit-core/models/class-material';

export class Diary {
  date: Date;
  dayOfWeek: number;
  dayOffType?: number;
  isDayOff?: number;
  dayOffFrom?: Date;
  dayOffTo?: Date;
  subjects: DiarySubject[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.subjects = item.subjects ? item.subjects.map((x: any) => new DiarySubject(x)) : [];
    this.date = item.date ? new Date(item.date) : undefined;
    this.dayOffFrom = item.dayOffFrom ? new Date(item.dayOffFrom) : undefined;
    this.dayOffTo = item.dayOffTo ? new Date(item.dayOffTo) : undefined;
  }
}

export class DiarySubject {
  number: number;
  subject: string;
  homework: string;
  isRequiredToSubmit: boolean;
  className?: string;
  lessonContent?: string;
  isChecked?: boolean;
  homeworkId?: string;
  marks?: Mark[];
  homeworkImplementationId?: string;
  homeworkAttachments?: AttachedFile[];
  urls?: Url[];
  isFds: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.number = item.number !== null ? +item.number : undefined;
    this.marks = item.marks ? item.marks.map((x: any) => new Mark(x)) : [];
    this.homeworkAttachments = item.homeworkAttachments ? item.homeworkAttachments.map((x: any) => new AttachedFile(x)) : [];
    this.urls = item.urls ? item.urls.map((x: any) => new Url(x)) : [];
  }
}

export class SemesterBounds {
  date?: Date[];
  from?: Date;
  to?: Date;

  constructor(item: any = {}) {
    this.from = item.from ? new Date(item.from) : undefined;
    this.to = item.to ? new Date(item.to) : undefined;
  }
}

export class Url {
  name: string;
  url: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
