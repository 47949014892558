<kendo-dropdownlist class="dropdown-filter"
                    [data]="data"
                    [disabled]="disabled"
                    [defaultItem]="defaultItem"
                    [value]="selectedValue"
                    [valuePrimitive]="true"
                    [textField]="textField"
                    [valueField]="valueField"
                    (valueChange)="onChange($event)">
  <ng-template kendoDropDownListNoDataTemplate>
    <div class="container">
      <div class="content">
        Дані відсутні
      </div>
    </div>
  </ng-template>

  <ng-template kendoDropDownListValueTemplate let-dataItem>
    <div class="d-flex align-items-center selected">
      <span class="selected-name">{{dataItem?.[textField]}}</span>
      <span *ngIf="dataItem.isFds" class="fds-label ml-1">ШПД</span>
    </div>
  </ng-template>

  <ng-template kendoDropDownListItemTemplate let-dataItem>
    <div class="d-flex align-items-center option">
      <span class="name-option">{{dataItem?.[textField]}}</span>
      <span *ngIf="dataItem.isFds" class="fds-label ml-1">ШПД</span>
    </div>
  </ng-template>
</kendo-dropdownlist>
