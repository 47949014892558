import {NgModule} from '@angular/core';
import {DropdownFilterComponent} from './dropdown-filter.component';
import {DropDownListModule} from '@progress/kendo-angular-dropdowns';
import {CommonModule} from '@angular/common';
import {DropdownModule} from 'src/app/private/pages/basic-data/components/dropdown/dropdown.module';

@NgModule({
  declarations: [
    DropdownFilterComponent
  ],
  imports: [
    CommonModule,
    DropDownListModule,
    DropdownModule
  ],
  exports: [
    DropdownFilterComponent
  ]
})
export class DropdownFilterModule { }
