import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CompositeFilterDescriptor} from '@progress/kendo-data-query';
import {BaseFilterCellComponent} from '@progress/kendo-angular-grid';
import {ClassesForJournalKeeping} from '@nit-core/models/marks-changelog';
import {TeacherClasses} from '@nit-core/models/homework';

@Component({
  selector: 'nit-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.scss']
})
export class DropdownFilterComponent extends BaseFilterCellComponent implements OnInit {
  @Input() component: string;
  @Input() filter: CompositeFilterDescriptor;
  @Input() data: any[];
  @Input() textField: string;
  @Input() valueField: string;
  @Input() defaultText: string;
  @Input() defaultValue: string;
  @Input() disabled: boolean = false;
  @Input() noDefaultItem: boolean = false;
  @Output() changeData: EventEmitter<any> = new EventEmitter<any>();

  defaultItem: any;

  get selectedValue(): any {
    const filter = this.filterByField(this.valueField);
    if (!filter && this.defaultValue) {
      return this.defaultValue;
    }
    if (this.component === 'journalKeeping') {
      return filter ? (filter.value === null) ? 'Інше' : filter.value : '';
    } else if (this.component === 'requests-grid') {
      return filter && filter.value !== 4 ? filter.value : null;
    } else {
      return filter ? filter.value : null;
    }
  }

  ngOnInit(): void {
    this.defaultItem = this.noDefaultItem ? null : {
      [this.textField]: this.defaultText,
      [this.valueField]: this.defaultValue || ''
    };
  }

  onChange(value: any): void {
    switch (this.component) {
    case 'user-grid': {
      if (this.valueField === 'studentClassId' && !value) {
        this.removeFilter('isInClass');
        this.applyFilter(this.removeFilter(this.valueField));
      } else if (this.valueField === 'studentClassId' && value) {
        if (value === 'non') {
          this.removeFilter(this.valueField);
          this.applyFilter(
            this.updateFilter({
              field: 'isInClass',
              operator: 'eq',
              value: false
            })
          );
        } else {
          this.removeFilter('isInClass');
          this.applyFilter(
            this.updateFilter({
              field: 'studentClassId',
              operator: 'eq',
              value
            })
          );
        }
      }
    } break;

    case 'remark': {
      this.applyFilter(
        value === '' ?
          this.removeFilter('teachers.id') :
          this.updateFilter({
            field: 'teachers.id',
            operator: 'eq',
            value
          })
      );
    } break;

    case 'journalKeeping':
    case 'homeworkTable': {
      if (this.data[0] instanceof ClassesForJournalKeeping || this.data[0] instanceof TeacherClasses) {
        this.removeFilter('subject');
        this.removeFilter(this.component === 'journalKeeping' ? 'group' : 'groupId');
      }
      if (this.valueField === 'subject') {
        this.removeFilter(this.component === 'journalKeeping' ? 'group' : 'groupId');
      }
      this.applyFilter(
        value === '' ?
          this.removeFilter(this.valueField) :
          this.updateFilter({
            field: this.valueField,
            operator: value ? 'eq' : 'isnull',
            value
          })
      );
    } break;

    case 'requests-grid': {
      this.applyFilter(
        this.updateFilter({
          field: this.valueField,
          operator: value === '' ? 'neq' : 'eq',
          value: value === '' ? 4 : value
        })
      );
    } break;

    case 'payment.type':
    case 'payment.status': {
      this.applyFilter(
        value === '' || value === 'all' ?
          this.removeFilter(this.component) :
          this.updateFilter({
            field: this.component,
            operator: 'eq',
            value: value
          })
      );
      break;
    }

    case 'mark':
    case 'sender':
    case 'child': {
      if (value === 'Предмет' || value === 'Клас') {
        value = '';
      }
      this.applyFilter(
        this.updateFilter({
          field: this.component + '.' + this.valueField,
          operator: value === '' ? 'neq' : 'eq',
          value: value === '' ? this.valueField === 'subType' ? 6 : '' :
            this.valueField === 'subType' ? value + 1 : value
        })
      );
    } break;

    case 'socialGroups': {
      if (this.valueField === 'type') {
        this.applyFilter(
          value === '' ?
            this.removeFilter(this.component + '.' + this.valueField) :
            this.updateFilter({
              field: this.component + '.' + this.valueField,
              operator: 'eq',
              value
            })
        );
      }
      else if (this.valueField === 'periodType') {
        this.applyFilter(
          value === '' || value === 'all' ?
            this.removeFilter(this.component + '.to') :
            this.updateFilter({
              field: this.component + '.to',
              operator: value === 'active' ? 'gte' : 'lte',
              value: value === 'active' ? new Date(new Date().setHours(0, 0, 0)).toISOString() : new Date(new Date().setHours(23, 59, 59)).toISOString()
            })
        );
      }
    }
      break;

    case 'healthGroup': {
      this.applyFilter(
        value === '' || value === 'all' ?
          this.removeFilter('mainTo')
          :
          this.updateFilter({
            field: 'mainTo',
            operator: value === 'active' ? 'gte' : 'lte',
            value: new Date().toISOString()
          })
      );

    }
      break;

    default: {
      this.applyFilter(
        value === '' ?
          this.removeFilter(this.valueField) :
          this.updateFilter({
            field: this.valueField,
            operator: 'eq',
            value
          })
      );
    }
    }

    this.changeData.emit(value);
  }
}
