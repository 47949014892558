import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, finalize, switchMap, tap} from 'rxjs/operators';
import {NitToastr, UserService} from '@nit-services';
import {AuthService} from '@nit-core/auth/auth.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {PermissionService} from '@nit-core/permission/permission.service';
import {sortClasses} from '@nit-core/methods';

@Injectable()
export class AuthGuard implements CanActivate {

  permissions = null;
  profile = null;
  profileURL: string = '';
  schoolInfo = null;
  noAccess: boolean = false;

  constructor(private readonly _router: Router,
              private readonly _oauthService: OAuthService,
              private readonly _userService: UserService,
              private readonly _ngxPermissionsService: NgxPermissionsService,
              private readonly _notification: NitToastr,
              private readonly _permissionService: PermissionService,
              private readonly _authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return forkJoin({
      hasToken: of(!!this._oauthService.hasValidAccessToken()),
      hasRefreshToken: of(!!this._oauthService.getRefreshToken())
    }).pipe(
      switchMap(() => this._initializePermissions()),
      switchMap(() => this._initializeSchoolInfo()),
      switchMap(() => this._initUserProfile(state)),
      switchMap(() => this._checkRoleForRedirection(state)),
      catchError(() => this._logOut())
    );
  }

  private _initializePermissions(): Observable<boolean> {
    if (this.permissions) {
      return of(true);
    }

    return this._userService.getPermissions(this._authService.userId, true).pipe(
      tap((response) => {
        if (response.isDeleted) {
          this.noAccess = true;

          return true;
        }
        const savedSchoolIdOriginal = Number(localStorage.getItem('schoolId'));
        if (savedSchoolIdOriginal && response.permissions.find(permission =>
          permission.schoolId === savedSchoolIdOriginal && permission.forUserId === response.userId && permission.active) === null) {
          localStorage.removeItem('schoolId');
        }
        this.permissions = [];
        let userPermissions = [];
        let schoolPermissions = [];
        response.permissions.forEach((item) => {
          if (!item.active) {
            return;
          }
          if (item.forUserId === response.userId) {
            this._userService.isChild$.next(item.isChild);
            this.profileURL = item.isChild ? '/profile/edit-child' : '/profile/edit';

            if (item.schoolId === 0) {
              userPermissions = item.permissions;
            } else {
              if (!localStorage.getItem('schoolId')) {
                localStorage.setItem('schoolId', String(item.schoolId));
              }
            }
          } else {
            this._authService.setChildSchoolId(item.forUserId, item.schoolId);
          }
        });

        const savedSchoolId = Number(localStorage.getItem('schoolId'));
        if (savedSchoolId) {
          schoolPermissions = response.permissions.find(permission =>
            permission.schoolId === savedSchoolId && permission.forUserId === response.userId && permission.active).permissions;
        }
        this.permissions = [...userPermissions, ...schoolPermissions];
        this._ngxPermissionsService.flushPermissions();
        this._ngxPermissionsService.loadPermissions(this.permissions);
        this._authService.setOwnSchoolId(savedSchoolId);
        this._userService.userPermissions$.next(response);
      }),
      switchMap(() => of(true)),
      catchError(() => {
        return of(false);
      })
    );
  }

  private _initializeSchoolInfo(): Observable<boolean> {
    const schoolId = this._authService.getUserSchoolId();
    if (this.schoolInfo || schoolId === 0) {
      return of(true);
    }

    return this._userService.getSchoolInfo(this._authService.userId).pipe(
      tap((schoolInfo) => {
        this.schoolInfo = schoolInfo;
        this.schoolInfo?.classes.sort(sortClasses);
        this._userService.isClassTeacher$.next(!!schoolInfo.classId && schoolInfo.teacherId === this._authService.userId ? schoolInfo : null);
        this._userService.currentSchoolInfo$.next(schoolInfo);
      }),
      switchMap(() => of(true)),
      catchError(() => {
        return of(false);
      })
    );
  }

  private _initUserProfile(state): Observable<boolean> {
    if (this.noAccess) {
      throw new Error('User account no longer valid');
    }
    if (this.profile) {
      return of(true);
    }

    return this._userService.read(this._authService.userId).pipe(
      tap((profile) => {
        this.profile = profile;
        this._userService.currentUser$.next(profile);
        if (!profile.fullName) {
          if (state.url !== this.profileURL && this._router.url !== this.profileURL) {
            this._router.navigate([this.profileURL]);

            return of(true);
          }
          if (state.url !== this.profileURL) {
            this._notification.warning('Будь ласка, спочатку заповніть обов\'язкові дані');
          }

          return of(state.url === this.profileURL);
        }
      }),
      switchMap(() => of(true)),
      catchError(() => {
        return of(false);
      })
    );
  }

  private _checkRoleForRedirection (state): Observable<boolean> {
    const schoolId = this._authService.getUserSchoolId();
    const isChild = this._userService.isChild$.getValue();
    const isSupport = this._permissionService.hasPermission(['create:school']);

    if (!localStorage.getItem('loginRedirect')) {
      localStorage.setItem('loginRedirect', JSON.stringify(true));
      if (schoolId === 0 && state.url === '/dashboard') {
        this._router.navigate([isChild ? '/profile-not-joined' : isSupport ? '/school-list' : '/children']);
      } else if (this._permissionService.hasPermission(['get:governance-school'])) {
        this._router.navigate(['/school-reports-welcome']);
      } else if (schoolId !== 0 && state.url === '/dashboard' && isChild) {
        this._router.navigate(['/diary']);
      }
    }

    return of(true);
  }

  private _logOut(): Observable<boolean> {
    return of(false).pipe(
      finalize(() => this._router.navigate(['login'])),
      finalize(() => localStorage.clear()),
      finalize(() => sessionStorage.clear()),
    );
  }
}
