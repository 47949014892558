import {Injectable} from '@angular/core';
import {RestService} from '@nit-core/services/global/http-services/rest.service';
import {Observable} from 'rxjs';
import {
  FilterClassReport,
  FilterJournalVisitingReport,
  FilterMarksChangelogReport,
  FilterSocialGroupReport,
  FilterUsersReport,
  GeneralReport,
  GradeBookLoggingReport,
  Report
} from '@nit-core/models/report';
import {FileType} from '@nit-core/global/domain/enums';
import {Filter} from '@nit-core/models/filter';
import {SafeguardReport} from '@nit-core/models';
import {Profile} from '@nit-core/models';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends RestService<Report> {

  constructor() {
    super(Report, '/reports', 'reports');
  }

  getReportFile(url: string): Observable<Blob> {
    return this.http.get(url, {headers: this.getApiHeaders(), responseType: 'blob'});
  }

  requestJournalReport(filter: Filter, fileType: FileType): Observable<any> {
    return this.httpPost(`${this.apiHref()}/journal/${fileType}`, filter);
  }

  requestJournalVisitingReport(filter: FilterJournalVisitingReport, fileType: FileType): Observable<any> {
    return this.httpPost(`${this.apiHref()}/journal/visiting/${fileType}`, filter);
  }

  requestMarksChangelogReport(filter: FilterMarksChangelogReport, fileType: FileType): Observable<any> {
    return this.httpPost(`${this.apiHref()}/marks-changelog/${fileType}`, filter);
  }

  requestCompleteReport(filter: FilterClassReport, fileType: FileType): Observable<any> {
    return this.httpPost(`${this.apiHref()}/complete-report/${fileType}`, filter);
  }

  requestDiscourseReport(filter: Filter, fileType: FileType): Observable<any> {
    return this.httpPost(`${this.apiHref()}/discourse-journal/${fileType}`, filter);
  }

  requestBriefingReport(filter: Filter, fileType: FileType): Observable<any> {
    return this.httpPost(`${this.apiHref()}/briefing-journal/${fileType}`, filter);
  }

  requestStudentsGeneralInfoReport(filter: Filter, fileType: FileType): Observable<any> {
    return this.httpPost(`${this.apiHref()}/user-general-info/${fileType}`, filter);
  }

  requestMovementReport(filter: Filter, fileType: FileType): Observable<any> {
    return this.httpPost(`${this.apiHref()}/movement-table/${fileType}`, filter);
  }

  requestFinalMarksReport(filter: Filter, fileType: FileType, user?: Profile): Observable<any> {
    return this.httpPost(`${this.apiHref()}/final-marks/${fileType}`, filter, user?.schoolId, user?.id);
  }

  requestGeneralFinalMarksReport(filter: Filter, fileType: FileType): Observable<any> {
    return this.httpPost(`${this.apiHref()}/general-final-marks/${fileType}`, filter);
  }

  requestJournalRemarkReport(filter: Filter, fileType: FileType): Observable<any> {
    return this.httpPost(`${this.apiHref()}/journal-remarks/${fileType}`, filter);
  }

  requestPracticeReport(filter: Filter, fileType: FileType): Observable<any> {
    return this.httpPost(`${this.apiHref()}/practice-journal/${fileType}`, filter);
  }

  requestUsersReport(filter: FilterUsersReport, fileType: FileType): Observable<void> {
    return this.httpPost(`${this.apiHref()}/users/${fileType}`, filter);
  }

  gradeBookLoggingReport(filter: GradeBookLoggingReport, fileType: FileType): Observable<void> {
    return this.httpPost(`${this.apiHref()}/grade-book-logging/${fileType}`, filter);
  }

  commonReport(filter: GeneralReport, fileType: FileType): Observable<void> {
    return this.httpPost(`${this.apiHref()}/common-report/${fileType}`, filter);
  }

  requestAchievement(filter: Filter, fileType: FileType, user?: {schoolId: number, id: string}): Observable<void> {
    return this.httpPost(`${this.apiHref()}/achievement-report/${fileType}`, filter, user?.schoolId, user?.id);
  }

  requestSafeguardReport(filter: SafeguardReport, fileType: FileType): Observable<void> {
    return this.httpPost(`${this.apiHref()}/safeguard-report/${fileType}`, filter);
  }

  educationFormReport(filter: Filter, fileType: FileType): Observable<void> {
    return this.httpPost(`${this.apiHref()}/education-form-report/${fileType}`, filter);
  }

  socialGroupReport(filter: FilterSocialGroupReport, fileType: FileType): Observable<void> {
    return this.httpPost(`${this.apiHref()}/social-group/${fileType}`, filter);
  }

  downloadAllReports(fileType: {type: string | null}): Observable<Blob> {
    return this.httpPostBlob(`${this.apiHref()}/zip`, fileType);
  }

  signedReport(fileId: string, data: {fileId: string}): Observable<void> {
    return this.httpPost(`${this.apiHref()}/${fileId}/sign`, data);
  }

  journalMarksReport(filter: Filter, fileType: FileType): Observable<void> {
    return this.httpPost(`${this.apiHref()}/child-marks/${fileType}`, filter);
  }
}
