import {GeneralPositionsEnum, SexType, UserRole, UserType} from '@nit-core/global/domain/enums';
import {getFullName, getShortName} from '@nit-core/methods';
import {User} from './common/user';
import {SocialGroupInfo} from './social-group';

export class SchoolSubject {
  schoolId: number;
  schoolName: string;
  schoolFullName: string;
  subjects: UserSubject[];
  positions: GeneralPositionsEnum[];
  isFdsActive: boolean;
  isGpdActive: boolean;
  fdsPosition: number;

  constructor(item: any = {}) {
    Object.assign(this, item);

    this.subjects = item.subjects ? item.subjects.map(subject => new UserSubject(subject)) : [];
  }
}

export class UserSubject {
  id: string;
  name: string;
  isFds: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class Profile {
  id: string;
  email: string;
  birthDay: Date;
  firstName: string;
  lastName: string;
  middleName: string;
  phoneNumber: string;
  emailConfirmed: boolean;
  code: string;
  profileImage: any;
  address: ProfileAddress;
  subjects: SchoolSubject[];
  workPlace: ProfileWorkPlace;
  additionalInfo: ProfileAdditionalInfo;
  personalFile: PersonalFile;
  droppedSchool: DroppedSchool;
  relations: Relation[];
  graduated: Graduated;
  fullName: string;
  sex: SexType;
  isChecked: boolean;
  idHomework: string;
  dateHomework: Date;
  briefingListened: boolean;
  isBusy?: boolean;
  isChild: boolean;
  schoolId?: number;
  classId?: string;
  shortName?: string;
  groupNumber?: string;
  educationType?: number;
  userId?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.birthDay = item.birthDay ? new Date(item.birthDay) : undefined;
    this.dateHomework = item.dateHomework ? new Date(item.dateHomework) : undefined;
    this.address = item.address ? new ProfileAddress(item.address) : undefined;
    this.workPlace = item.workPlace ? new ProfileWorkPlace(item.workPlace) : undefined;
    this.additionalInfo = item.additionalInfo ? new ProfileAdditionalInfo(item.additionalInfo) : undefined;
    this.relations = item.relations ? item.relations.map((x: any) => new Relation(x)) : undefined;
    this.graduated = item.graduated ? new Graduated(item.graduated) : undefined;
    this.droppedSchool = item.droppedSchool ? new DroppedSchool(item.droppedSchool) : undefined;
    this.subjects = item.subjects ? item.subjects.map(schoolWithSubject => new SchoolSubject(schoolWithSubject)) : [];
    this.shortName = getShortName(item.firstName, item.lastName, item.middleName);
  }
}

export class SupportProfile {
  id: string;
  email: string;
  birthDay: Date;
  firstName: string;
  lastName: string;
  middleName: string;
  fullName: string;
  phoneNumber: string;
  sex: SexType;
  emailConfirmed: boolean;
  code: string;
  type: UserType;
  relations: Relation[];
  shortName?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.birthDay = item.birthDay ? new Date(item.birthDay) : undefined;
    this.relations = item.relations ? item.relations.map((x: any) => new Relation(x)) : undefined;
    this.shortName = getShortName(item.firstName, item.lastName, item.middleName);
  }
}

export class ProfileAddress {
  building: string;
  city: string;
  country: string;
  region: string;
  street: string;
  fullAddress: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.fullAddress = [this.city, this.street, this.building].filter(Boolean).join(', ');
  }
}

export class ProfileWorkPlace {
  address: string;
  institutionName: string;
  phone: string;
  position: string;
  workPlaceAddress: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.workPlaceAddress = [this.institutionName, this.phone].filter(Boolean).join(', ');
  }
}

export class ProfileAdditionalInfo {
  socialGroup: string[];
  individuality: string;
  interests: string;
  socialGroups: SocialGroupInfo[];

  get concatedSocial(): string {
    return this.socialGroup?.length ? this.socialGroup.join(', ') : '';
  }

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class DroppedSchool {
  dateDropped: Date;
  region: string;
  place: string;
  school: string;
  class: string;
  dropOrderNumber: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.dateDropped = item.dateDropped ? new Date(item.dateDropped) : null;
  }
}

export class PersonalFile {
  personalFile: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class Relation extends User {
  id: string;
  code: string;
  emailConfirmed: boolean;
  workPlace: ProfileWorkPlace;
  fullNameConcat: string;

  constructor(item: any = {}) {
    super();
    Object.assign(this, item);
    this.fullNameConcat = getFullName(item.firstName, item.lastName, item.middleName);
    this.workPlace = item.workPlace ? new ProfileWorkPlace(item.workPlace) : undefined;
  }
}

export class SchoolInfo {
  classId: string;
  className: string;
  schoolName: string;
  teacherId: string;
  teacherName: string;
  teacherFullName: string;
  hasNushRule: boolean;
  teacherImg: string;
  teacherPhoneNumber: string;
  classes: ClassOfTutor[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.classes = item.classes?.length ? item.classes.map(schoolClass => new ClassOfTutor(schoolClass)) : [];
  }
}

export class ClassOfTutor {
  id: string;
  name: string;
  hasNushRule: boolean;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class ClassTeacherItem {
  classTeacherId: string;
  teacherFullName: string;
  classes?: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class Graduated {
  year: number;
  school: string | null;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export interface IdentityClaims {
  first_name: string;
  last_name: string;
  middle_name: string;
  name: string;
  preferred_username: string;
  role: UserRole;
  uids: string;
  sub: string;
}

export class AuthIdentityClaims {
  first_name: string;
  last_name: string;
  middle_name: string;
  name: string;
  preferred_username: string;
  role: UserRole | UserRole[];
  uids: IdentityUid[];
  sub: string;
  schoolIds: string[];

  constructor(item: any) {
    if (item) {
      Object.assign(this, item);
      this.uids = item.uids ? JSON.parse(item.uids) : undefined;
      this.schoolIds = this.uids?.length ? [...new Set(this.uids.map(x => x.school_uid))] : undefined;
    }

  }
}

export interface IdentityUid {
  id: string;
  school_uid: string;
}

export class UserHomework {
  answer: string;
  className: string;
  date: Date;
  groupNumber: string;
  id: string;
  isChecked: boolean;
  journalId: string;
  subject: string;
  submittedAttachmentCount: number;
  userFullName: string;
  userId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.date = item.date ? new Date(item.date) : undefined;
  }
}

export class RemoveRelations {
  childId: string;
  parentId: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}
