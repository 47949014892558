<div #nusPopover class="container-checkbox">
  <ng-container *ngFor="let control of configControls | keyvalue">
    <ng-container *ngIf="grading[control?.value?.type] ||
                    (control?.key === 'absense' && isAbsenseAllowed)"
                  [formGroup]="form">
      <ng-container *ngFor="let item of control.value?.controls">
        <nit-mark-item [form]="form"
                       [formControlName]="control?.key"
                       [controlName]="control?.key"
                       [isVerbalControl]="control?.key === 'isVerbalFormed'"
                       [value]="item.value">
          <ng-container *ngIf="control?.key !== 'absense' && control?.key !== 'isVerbalFormed'">
            {{item.name}}
          </ng-container>
          <span *ngIf="control?.key === 'absense'" class="absent">H</span>
          <img *ngIf="control?.key === 'isVerbalFormed'" src="/assets/icons/green-tick.svg" alt="Green tick">
        </nit-mark-item>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isCommentAllowed">
    <div *ngIf="!form?.get('description')?.value"
         class="large-block simple-text add-comment"
         (click)="addComment()">
      <span>Додати коментар</span></div>
    <ng-container *ngIf="form?.get('description')?.value">
      <div class="large-block simple-text add-comment"
           (click)="editComment()">
        <span>Редагувати коментар</span>
      </div>
      <div class="large-block simple-text delete-comment"
           (click)="removeComment()">
        <span>Видалити коментар</span>
      </div>
    </ng-container>
  </ng-container>
</div>
