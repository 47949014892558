export enum ConfirmationType {
  ConfirmAccount,
  ChangePassword,
  ConfirmEmail,
  AddEmail,
  ChangeEmail,
  ChangeNotVerifiedEmail
}

export enum PaymentSystemType { LiqPay }

export enum WarningColorType { Red = 0, Blue = 1, Yellow = 2 }

export enum UserType { Parent, Child, Teacher, Admin, NotSorted }

export enum UserGridType { Teacher = 'teacher', Child = 'student', Admin = 'admin' }

export enum ClassUserGridType { Child = 'student', Teacher = 'teacher', Parent = 'parent' }

export enum NotificationType {
  ParentConnect,
  ChildToSchool,
  TeacherToSchool,
  RejectRequest = 4,
  ApproveRequest,
  MarkChanges,
  EventNotification,
  BriefingJournalConfirmation,
  TeacherReplaced,
  JournalRemarkCreated,
  YearCloseStarted,
  YearCloseFinished,
  PaymentReminder,
  GrantedScoreCardAccess,
  JournalMarkCreated,
  PublishedSchedule,
  PublishedNews,
  Unknown = 1000
}

export enum UserRole {
  Any = 'ANY', User = 'USER', Child = 'CHILD', Teacher = 'TEACHER',
  Director = 'DIRECTOR', Admin = 'ADMINISTRATOR', Support = 'SUPPORT'
}

export enum RequestStatus { Pending, Approval, Reject, NotConfirmed }

export enum FrequencyStatus { OneTime, Monthly }

export enum PaymentStatus { Unpaid, Paid, Reject, Processing, Error }

export enum MarkSubType { AddRating, EditRating, RemoveRating, AddPresence, RemovePresence }

export enum RequestType { Child, School, Teacher, Mark = 4, Payment = 5 }

export enum RequestGridType { Materials = 'materials', Users = 'users', Mark = 'mark', Payment = 'payment' }

export enum ServiceType { Auth, Api, Maintenance }

export enum JournalColumnType {
  Day,
  Thematic,
  Semester,
  Year,
  Custom,
  SemesterChanged = 6,
  DPA = 8,
  AverageMarkSemester,
  YearChanged = 10,
  AverageMarkThematic,
  AverageMarkYear,
  CustomSemester,
  CustomYear,
  CustomSemesterChanged,
  CustomYearChanged,
  LearningOutcome,
}

export enum SexType { Male, Female, Other }

export enum FileType { Pdf = 'pdf', Xlsx = 'xlsx' }

export enum SchoolType { School, Lyceum, Gymnasium, PrimarySchool, SupportInstitution }

export enum LegalForm { Governmental, Private, Communal }

export enum AdministratorRole {
  Director, Secretary, TeacherOrganizer, Psychologist,
  Assistant1, Assistant2, Assistant3, Assistant4, Assistant5, Assistant6
}

export enum MarkChangeType {
  AddedRating,
  Edit,
  DeletedRating,
  AddedAbsent,
  DeletedAbsent,
  AddedComment,
  DeletedComment,
  DeletedWithJournal
}

export enum SignalRHubMethod { Test = 'test' }

export enum FeathersHubService {
  Media = 'media',
  Whiteboards = 'whiteboards',
  Classes = 'classes',
  Rooms = 'rooms',
  Messages_v2 = 'messages_v2',
  Notification = 'mute-notifications',
  Pinnes = 'pinnes',
  Saves = 'saves',
  RoomMember = 'room-member',
  Files = 'files',
  Join = 'join',
  Channels = 'channels',
}

export enum FeathersHubMethod {
  CallStarted = 'media created',
  CallEnded = 'media removed',
  CallDeclined = 'media declined',
  WhiteboardsOpen = 'whiteboards created',
  WhiteboardsRemove = 'whiteboards removed',
  WhiteboardsPatched = 'whiteboards patched',
  MessageReceived_v2 = 'subscriptions_v2 patched',
  Created = 'created',
  Removed = 'removed',
  Patched = 'patched',
  notificationsMuteRemoved = 'mute-notifications removed',
  notificationsMuteCreate = 'mute-notifications created',
  pinnesCreate = 'pinnes created',
  pinnesRemove = 'pinnes removed',
  roomsRemove = 'rooms removed',
  roomsCreated = 'rooms created',
  roomsPatched = 'rooms patched',
  roomsLastMessage = 'rooms lastMessage',
  channelsLastMessage = 'channels lastMessage',
  messages_v2Created = 'messages_v2 created',
  messages_v2Read = 'messages_v2 read',
  messages_v2Patched = 'messages_v2 patched',
  UserStatusOnline = 'user-status online',
  UserStatusOffline = 'user-status offline',
  SavesCreated = 'saves created',
  Messages_v2Removed = 'messages_v2 removed',
  LeaveRoom = 'rooms leave',
}

export enum AutoCompleteType { Region, City, Street }

export enum DayOffType { Holiday, DayOff, Quarantine, Other }

export const CustomMarkType = [
  {value: 0, text: 'зв.'},
  {value: 1, text: 'н/а'},
  {value: 2, text: 'зарах.'},
  {value: 3, text: 'вивч.'},
  {value: 4, text: 'н/о'},
  {value: 5, text: 'не\nзарах.'}
];

export enum WorkContentType {
  Practice,
  Excursions
}

export enum KendoFilterLogic {
  Or = 'or',
  And = 'and'
}

export enum KendoFilterOperator {
  Contains = 'contains',
  DoesNotContain = 'doesnotcontain',
  Eq = 'eq',
  Neq = 'neq',
  IsNull = 'isnull',
  IsNotNull = 'isnotnull',
  Gte = 'gte',
  Gt = 'gt',
  Lte = 'lte',
  Lt = 'lt',
  IsEmpty = 'isempty',
  IsNotEmpty = 'isnotempty',
  StartsWith = 'startswith',
  DoesNotStartWith = 'doesnotstartwith',
  EndsWith = 'endswith',
  DoesNotEndWith = 'doesnotendwith',
  In = 'in',
  NotIn = 'notin',
  InAsObject = 'inasobject',
  NotInAsObject = 'notinasobject',
  AnyEq = 'anyeq',
}

export enum KendoSortDir {
  Asc = 'asc',
  Desc = 'desc'
}

export enum AbsenceType { Absent, Sick }

export enum Actions {
  Done, Read
}

export enum JournalType {
  Journal, PracticeJournal, BriefingJournal
}

export enum AdminStatus {
  Done, PartialDone, NotDone
}

export const TypeMarksEventsChange = [
  {name: 'Нова оцінка', changeType: MarkChangeType.AddedRating},
  {name: 'Виправлення оцінки', changeType: MarkChangeType.Edit},
  {name: 'Видалення оцінки', changeType: MarkChangeType.DeletedRating},
  {name: 'Нова "Н"', changeType: MarkChangeType.AddedAbsent},
  {name: 'Видалення "Н"', changeType: MarkChangeType.DeletedAbsent},
  {name: 'Новий коментар', changeType: MarkChangeType.AddedComment},
  {name: 'Видалення коментаря', changeType: MarkChangeType.DeletedComment},
  {name: 'Видалено з журналом', changeType: MarkChangeType.DeletedWithJournal}
];

export const TypeMarksChange = [
  {name: 'Поточна', columnType: JournalColumnType.Day},
  {name: 'Тематична', columnType: JournalColumnType.Thematic},
  {name: 'Семестрова', columnType: JournalColumnType.Semester},
  {name: 'Річна', columnType: JournalColumnType.Year},
  {name: 'Оцінка контролю', columnType: JournalColumnType.Custom},
  {name: '', columnType: ''},
  {name: 'Семестрова скоригована', columnType: JournalColumnType.SemesterChanged},
  {name: '', columnType: ''},
  {name: 'ДПА', columnType: JournalColumnType.DPA},
  {name: '', columnType: ''},
  {name: 'Річна скоригована', columnType: JournalColumnType.YearChanged},
  {name: '', columnType: ''},
  {name: '', columnType: ''},
  {name: 'Семестрова оцінка контролю', columnType: JournalColumnType.CustomSemester},
  {name: 'Річна оцінка контролю', columnType: JournalColumnType.CustomYear},
  {name: 'Семестрова скоригована оцінка контролю', columnType: JournalColumnType.CustomSemesterChanged},
  {name: 'Річна скоригована оцінка контролю', columnType: JournalColumnType.CustomYearChanged},
  {name: 'Компетенція', columnType: JournalColumnType.LearningOutcome},
];

export const TypeMarksChangeDropdown = [
  {name: 'Поточна', columnType: JournalColumnType.Day},
  {name: 'Тематична', columnType: JournalColumnType.Thematic},
  {name: 'Семестрова', columnType: JournalColumnType.Semester},
  {name: 'Річна', columnType: JournalColumnType.Year},
  {name: 'Оцінка контролю', columnType: JournalColumnType.Custom},
  {name: 'Семестрова скоригована', columnType: JournalColumnType.SemesterChanged},
  {name: 'ДПА', columnType: JournalColumnType.DPA},
  {name: 'Річна скоригована', columnType: JournalColumnType.YearChanged},
  {name: 'Семестрова оцінка контролю', columnType: JournalColumnType.CustomSemester},
  {name: 'Річна оцінка контролю', columnType: JournalColumnType.CustomYear},
  {name: 'Семестрова скоригована оцінка контролю', columnType: JournalColumnType.CustomSemesterChanged},
  {name: 'Річна скоригована оцінка контролю', columnType: JournalColumnType.CustomYearChanged},
  {name: 'Компетенція', columnType: JournalColumnType.LearningOutcome},
];

export enum ReportState { Ready, InProgress, Error }

export enum FileTypeEnum { Pdf, Xlsx }

export const TypeJournalRemark = [
  {name: 'Обов\'язково до виконання', RemarkAction: 0},
  {name: 'Обов\'язково до ознайомлення', RemarkAction: 1}
];

export enum CustomMark { Released, NotCertified, Accepted, Learned, NotRated, NotAccepted }

export enum LeaveType { Dropped, SecondYear, Graduated }

export enum AssessmentType { PlainVerbal, Leveled = 2, Digital }

export enum LeveledAssessmentType {
  Elementary, // П
  Medium, // С
  Sufficient, // Д
  High // В
}

export const LeveledMarkType = [
  {value: LeveledAssessmentType.Elementary, text: 'П'},
  {value: LeveledAssessmentType.Medium, text: 'С'},
  {value: LeveledAssessmentType.Sufficient, text: 'Д'},
  {value: LeveledAssessmentType.High, text: 'В'},
];

export enum ChatType { Private = 'private', Direct = 'direct', Default = 'default' }

export enum ChatMessageContextMenuActions {
  Reply = 'reply',
  Forward = 'forward',
  Pin = 'pin',
  unPin = 'unpin',
  Edit = 'edit',
  Save = 'save',
  Delete = 'delete',
  Move = 'move',
}

export enum ChatMessageContextMenuOptions {
  Reply = 'Відповісти',
  Forward = 'Переслати',
  Pin = 'Закріпити',
  unPin = 'Відкріпити',
  Edit = 'Редагувати',
  Save = 'Зберегти',
  Delete = 'Видалити',
  DeleteSave = 'Видалити зі Збереженого',
  MoveToMessage = 'Перейти до повідомлення',
}

export enum LessonType {
  Default,
  Custom
}

export enum SemesterNumber {
  First = 1,
  Second = 2
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum ComparisonType {
  Initial, ForFiles, ForNit
}

export enum ComparisonState {
  Ready, InProgress, Error
}

export enum FormsEducation {None, Family, PedagogicalPatronage, Externship, Remote }

export const FormsEducationType = [
  {type: FormsEducation.None, text: 'Без форми'},
  {type: FormsEducation.Family, text: 'Сімейна'},
  {type: FormsEducation.PedagogicalPatronage, text: 'Педагогічний патронаж'},
  {type: FormsEducation.Externship, text: 'Екстернат'},
  {type: FormsEducation.Remote, text: 'Дистанційна'},
];

export const FormsEducationTypeTable = [
  {type: FormsEducation.None, text: ''},
  {type: FormsEducation.Family, text: 'Сімейна'},
  {type: FormsEducation.PedagogicalPatronage, text: 'Педагогічний патронаж'},
  {type: FormsEducation.Externship, text: 'Екстернат'},
  {type: FormsEducation.Remote, text: 'Дистанційна'},
];

export enum Alternation {
  Weekly,
  Numerator,
  Denominator
}

export enum LessonVersionState {
  Saved,
  Modified
}

export enum NushViewModes {
  Admin = 'admin',
  ClassTeacher = 'class-teacher',
  Teacher = 'teacher',
  Parent = 'parent',
  Child = 'child',
}

export enum TeacherScheduleContextMenuOptions {
  AddVacancy = 'Додати вакансію',
  CopyDraft = 'Скопіювати чернетку на другий семестр',
  EditDraft = 'Редагувати назву чернетки',
  DeleteDraft = 'Видалити чернетку',
  Download = 'Завантажити'
}

export enum TeacherScheduleContextMenuValues {
  AddVacancy = 'add-vacancy',
  CopyDraft = 'copy-draft',
  EditDraft = 'edit-draft',
  DeleteDraft = 'delete-draft',
  Download = 'download'
}

export enum TeacherScheduleItemActions {
  Edit = 'edit',
  Delete = 'delete'
}

export enum MultipleFormsOfEducation {
  oneFormOfStudy, allFormsOfStudy
}

export enum ReplacementTypesNames {
  Teacher = 'вчитель',
  Vacancy = 'вакансія',
}

export enum ReplacementTypesValues {
  Teacher = 'teacher',
  Vacancy = 'vacancy',
}

export const ReplacementTypes: { name: ReplacementTypesNames, value: ReplacementTypesValues }[] = [
  {name: ReplacementTypesNames.Teacher, value: ReplacementTypesValues.Teacher},
  {name: ReplacementTypesNames.Vacancy, value: ReplacementTypesValues.Vacancy},
];

export enum HiddenLessonStatus {
  Show, Hide
}

export enum JournalHistoryActions {
  Create,
  Delete
}

export enum SocialGroupType
{
  Orphan,
  WithDisability,
  LargeFamily,
  LowIncomeFamily,
  SufferedNaturalDisasterAccidentCatastrophe,
  SufferedChernobylDisaster,
  ParentsDiedAccidentsAtWorkDuringOfficialDuties,
  RegisteredAtDispensary,
  HomelessAndNeglected,
  AgroIndustrialWorkers,
  InternallyDisplacedPerson,
  ATOParticipant,
  ServicemanDiedATO,
  DiedInCivilProtest,
  Talented,
  AdoptedFamilyTypeOrphanage,
  ChildrenServices
}

export const SocialGroupList = [
  {type: SocialGroupType.Orphan, text: 'Дитина-сирота, дитина, позбавлена батьківського піклування'},
  {type: SocialGroupType.WithDisability, text: 'Дитина з інвалідністю (здатна до самообслуговування)'},
  {type: SocialGroupType.LargeFamily, text: 'Дитина з багатодітної сім\'ї'},
  {type: SocialGroupType.LowIncomeFamily, text: 'Дитина з малозабезпеченої сім\'ї'},
  {type: SocialGroupType.SufferedNaturalDisasterAccidentCatastrophe, text: 'Дитина, яка постраждала внаслідок стихійного лиха, техногенний аварій, катастроф'},
  {type: SocialGroupType.SufferedChernobylDisaster, text: 'Дитина, постраждала внаслідок Чорнобильської катастрофи'},
  {type: SocialGroupType.ParentsDiedAccidentsAtWorkDuringOfficialDuties, text: 'Дитина, батьки якої загинули від нещасних випадків на виробництві або під час виконання службових обов\'язків'},
  {type: SocialGroupType.RegisteredAtDispensary, text: 'Дитина, яка перебуває на диспансерному обліку'},
  {type: SocialGroupType.HomelessAndNeglected, text: 'Безпритульна та бездоглядна дитина'},
  {type: SocialGroupType.AgroIndustrialWorkers, text: 'Дитина працівників агропромислового комплексу та соціальної сфери села'},
  {type: SocialGroupType.InternallyDisplacedPerson, text: 'Дитина, зареєстрована як внутрішньо переміщена особа'},
  {type: SocialGroupType.ATOParticipant, text: 'Дитина учасника АТО (учасника бойових дій)'},
  {type: SocialGroupType.ServicemanDiedATO, text: 'Дитина військовослужбовця, який загинув у районі проведення АТО'},
  {type: SocialGroupType.DiedInCivilProtest, text: 'Дитина загиблого учасника масових акцій громадянського протесту'},
  {type: SocialGroupType.Talented, text: 'Талановиті та обдаровані діти'},
  {type: SocialGroupType.AdoptedFamilyTypeOrphanage, text: 'Рідні діти батьків вихователів, або прийомних батьків, які проживають в одному дитячому будинку сімейного типу або в одній прийомній сім\'ї'},
  {type: SocialGroupType.ChildrenServices, text: 'Діти, взяті на облік службами у справах дітей, як такі, які перебувають у складних життєвих обставинах (СЖО)'}
];

export const QuestionnaireQuestions = [
  'Чи відчуваю я себе в безпеці, коли у класі є вчитель/вчителька?',
  'Чи цікаво мені на уроці?',
  'Чи маю я вільний час на уроці?',
  'Чи позитивно налаштований учитель/учителька при зустрічі зі мною?',
  'Чи пояснює учитель/учителька ключові поняття так, що я це розумію і можу використовувати?',
  'Я розумію мету і цілі кожного уроку і яка моя роль на уроці.',
  'Чи заохочує учитель/учителька до самостійного дослідження мною певної теми чи процесу додатково?',
  'Чи викликає у мене учитель/учителька зацікавлення до свого предмету?',
  'Чи зацікавлений сам учитель/учителька у своєму предметі?',
  'Вчитель/вчителька каже, що це нормально - робити помилки.',
  'Якщо я зробив/зробила помилку, то я завжди можу підійти до вчителя/вчительки та дізнатись, як її виправити',
  'Вчитель/вчителька зазвичай приділяє достатньо часу мені, щоб пояснити чому я помилився/помилилася і продемонструвати мій прогрес',
  'Зазвичай учитель/учителька позитивно оцінює зусилля, які я докладаю до вивчення теми/предмету.',
  'Чи є домашні завдання, які я отримую від учителя/учительки посильними та цікавими?',
  'Чи залучає мене учитель/учителька до досліджень/проєктів/незвичних підходів до роботи під час вивчення певної теми?',
  'Коли у мене виникають проблеми або запитання щодо уроку чи матеріалу, я завжди можу звернутись до вчителя/вчительки за порадою чи підтримкою.',
  'Чи звертаюсь я за консультацією до учителя/учительки на будь-яку тему?',
  'Я відчуваю, що до мене відносяться як до особистості.',
  'На уроці цього вчителя/вчительки зазвичай панує позитивна атмосфера.',
  'Я є активним/активною на уроці цього вчителя/вчительки.',
  'На уроках я відчуваю, що ми важливі.',
  'Я відчуваю, що ми з учителем/учителькою радше партнери і друзі, аніж «головний» і «підлеглий».',
  'Чи отримував/отримувала я після спілкування з учителем/учителькою відповіді на запитання, які в кінцевому результаті допомогли мені краще навчатися/стати більш успішним/успішною з предмета, який викладає учитель/учителька?',
  'Я вчусь багато в учителя/учительки. Він/вона є прикладом лідерства для мене.',
  'Мені хочеться приходити на уроки до цього вчителя/вчительки.',
];

export const Answers = [
  'Не погоджуюся',
  'Радше не погоджуюсь',
  'Не можу визначитись',
  'Радше погоджуюсь',
  'Абсолютно погоджуюсь'
];

export const PaymentSystem = [
  {type: PaymentSystemType.LiqPay, text: 'LiqPay'},
];

export const PaymentReminderFrequency = [
  {type: 0, text: '1-й день місяця'},
  {type: 1, text: '15-й день місяця'},
  {type: 2, text: '30-й день місяця'}
];

export enum PaymentTypes {
  Once, Monthly
}

export enum GeneralPositionsEnum {
  Director,
  DeputyDirectorOfEducationalWork,
  DeputyOfEducationalPedagogicWork,
  DeputyOfForeignLanguages,
  DeputyOfPrimaryClasses,
  DeputyOfPedagogicWork,
  DeputyOfEducationalWork,
  Teacher,
  PrimarySchoolTeacher,
  TeacherAssistant,
  PedagogueOrganizator,
  PracticalPsychologist,
  SocialPedagogue,
  HeadOfGroup,
  MasterOfIndustrialTraining,
  Educator,
  EducatorAssistant,
  HeadOfSpeechTherapyCenter,
  SpeechTherapistTeacher,
  SpeechTherapistTeacherAssistant,
  DefectologistTeacher,
  AssistantOfDefectologistTeacher,
  MusicDirector,
  PhysicalEducationInstructor,
  DeputyDirectorEconomicWork,
  HeadOfLibrary,
  Librarian,
  LaboratoryAssistant,
  Secretary
}

export enum FdsPositions {
  Teacher = 0,
  TeacherOfPrimaryClasses = 1,
  AssistantTeacher = 2
}

export enum AccessibleOlympiadPaymentStatus {
  Paid = 'payed',
  NotPaid = 'not-payed',
  Pending = 'pending'
}

export enum HealthCardGroupType {
  Main,
  Preparation,
  Special,
}
export enum ContentType {Text, Video, Audio, Materials, Questions, Image }
export enum AnswerType {Single, Multiple}

export enum ErrorPaymentMessages {
  UnconfirmedSettings = 'Неможливо активувати непідтверджене налаштування',
  ActivePayments = 'Налаштування містить активні оплати'
}
